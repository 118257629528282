import React, { useState, useEffect, useCallback } from "react";
import axiosWithAuth from "../../contexts/axiosWithAuth";
import {
  renderStatusBadge,
  formatMonthRange,
  formatPlanDates,
  amountPaid,
  totalAmount,
  BuildPaymentData,
  invoiceTemplate,
  DateReadingFormat,
  getPDSDocName,
  getTCDocName,
  getClaimFormDocname,
  getReimbursementClaimFormDocname,
  getPolicyDocName,
  PlansDetailsTableHeaders,
  PlansDetailsCertificatesTableHeaders,
  PlansDetailsTableRows,
  getDatavalue,
  getCallsvalue,
  getSMSvalue,
} from "../../utils/utils";

import html3pdf from "html3pdf";

const Main = () => {
  return (
    // <section
    //   className="inner--hero dashboard--hero"
    //   style={{ backgroundImage: `url(assets/images/background_hero.png)` }}
    // >
    <section className="inner--hero dashboard--hero theme-background">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="inner--text">
              <h4 className="tag-box">Dashboard</h4>
              <h3 className="heading">Your Personal Dashboard</h3>
              <p>
                Effortlessly Organize, Monitor, and Optimize Your Personal
                Information in Real Time
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Dashboard = () => {
  // State to hold loading status for each tab
  const [personalLoading, setPersonalLoading] = useState(true);

  const [billingLoading, setBillingLoading] = useState(true);

  // State to hold data for each tab
  const [personalData, setPersonalData] = useState(null);
  const [certificates, setCertificatesData] = useState(null);
  const [siteURL, setSiteURL] = useState(null);
  const [billingData, setBillingData] = useState(null);
  const [isClicked, setisClicked] = useState(false);

  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const handleFilter = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Check if both year and month are selected
    if (!year || !month) {
      // If not selected, display an error message
      alert("Please select both a year and a month.");
      return; // Exit the function to prevent further execution
    }
    setPersonalLoading(true);
    await fetchPersonalData();
  };

  const handleYear = (yearValue) => {
    setYear(yearValue);
  };
  const handleMonth = (monthValue) => {
    setMonth(monthValue);
  };

  // Function to fetch personal data
  const fetchPersonalData = useCallback(async () => {
    try {
      const response = await axiosWithAuth().get("/api/v1/myProfile", {
        params: {
          year: year,
          month: month,
        },
      });
      setPersonalData(response.data.customerDetails);
      setCertificatesData(response.data.certificates);
      setSiteURL(response.data.site_url);
      setPersonalLoading(false); // Set loading state to false after data is fetched
    } catch (error) {
      setPersonalLoading(false);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }
  }, [year, month]);

  const handlePaymentClicked = (value) => {
    setisClicked(value);
  };

  const fetchBillingData = async () => {
    try {
      const response = await axiosWithAuth().get("/api/v1/myBilling");
      setBillingData(response.data);
      setBillingLoading(false); // Set loading state to false after data is fetched
    } catch (error) {
      setBillingLoading(false);
      console.error("Error fetching billing data:", error);
    }
  };

  // Fetch data for initial tab when component mounts
  useEffect(() => {
    fetchPersonalData();
  }, [fetchPersonalData]);

  // Function to handle tab click
  const handleTabClick = async (tab) => {
    switch (tab) {
      case "personal":
        if (!personalData) {
          setPersonalLoading(true);
          await fetchPersonalData();
        }
        break;
      case "billing":
        if (!billingData) {
          setBillingLoading(true);
          await fetchBillingData();
        }
        break;
      default:
        break;
    }
  };

  return (
    <section className="dashboard--tab--area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-md-12">
            <div className="dashboard--tab">
              <div className="dashboard--navs--wrap">
                <div className="dashboard--navs">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-personal"
                        onClick={() => handleTabClick("personal")}
                      >
                        Personal Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-singlePlan"
                        onClick={() => handleTabClick("personal")}
                      >
                        SIGNAL Plan Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-payment"
                        onClick={() => handleTabClick("billing")}
                      >
                        Billing & Payment
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-disabled-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                      >
                        Contact Details
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-content mt_35" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal">
                  {/* dashboard info card */}
                  <Personal
                    data={personalData}
                    siteURL={siteURL}
                    loading={personalLoading}
                  />
                </div>
                <div className="tab-pane fade" id="pills-singlePlan">
                  {/* dashboard info card */}
                  <PlanDetails
                    data={personalData}
                    loading={personalLoading}
                    certificates={certificates}
                    handleFilter={handleFilter}
                    setYear={handleYear}
                    setMonth={handleMonth}
                    year={year}
                    month={month}
                  />
                </div>
                <div className="tab-pane fade" id="pills-payment">
                  {/* dashboard info card */}
                  <BillingInfo
                    onClick={handlePaymentClicked}
                    isClicked={isClicked}
                    data={billingData}
                    loading={billingLoading}
                  />
                </div>
                <div className="tab-pane fade" id="pills-contact">
                  {/* dashboard info card */}
                  <ContactInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Personal = ({ data, siteURL, loading }) => {
  if (loading) {
    return <div className="loading-spinner" />;
  }
  const img =
    data.profile_pic && data.profile_pic !== ""
      ? `${siteURL}/uploads/${data.profile_pic}`
      : "assets/images/default-user.png";
  return (
    <div className="dashboard--info--box personal--details card--default">
      <div className="title">
        <h3>Personal Details</h3>
      </div>
      <div className="profilepic--area">
        <div className="profile">
          <div className="profile--img">
            <img src={img} alt="" width={90} height={90} />
          </div>
          <div className="profile--username">
            <h4>{data.name || ""}</h4>
            <p>{data.email || ""}</p>
          </div>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-md-6 mt_20">
            <div className="input--group">
              <label htmlFor="dfname">Full Name</label>
              <input
                type="text"
                name="dfname"
                id="dfname"
                readOnly={true}
                value={data.name || ""}
              />
            </div>
            <div className="input--group">
              <label htmlFor="ddate">Date of Birth</label>
              <input
                type="text"
                name="ddate"
                id="ddate"
                readOnly={true}
                value={data.dob || ""}
              />
            </div>
            <div className="input--group">
              <label htmlFor="dphone">Mobile Phone Number</label>
              <input
                type="text"
                name="dphone"
                id="dphone"
                readOnly={true}
                value={data.mobile_no || ""}
              />
            </div>
            <div className="input--group">
              <label htmlFor="dphone">
                Mobile Phone Number Registered with SIGNAL{" "}
              </label>
              <input
                type="text"
                name="dphone"
                id="dphone"
                readOnly={true}
                value={data.signal_mobile_no || "N/A"}
              />
            </div>
            <div className="input--group">
              <label htmlFor="dpostcode">Post code</label>
              <input
                type="text"
                name="dpostcode"
                id="dpostcode"
                value={data.postcode || ""}
                readOnly={true}
              />
            </div>
          </div>
          <div className="col-md-6 mt_20">
            <div className="input--group">
              <label htmlFor="dnirc-number">NRIC/Passport Number</label>
              <input
                type="text"
                name="dnirc-number"
                id="dnirc-number"
                value={data.nric_or_passport || ""}
                readOnly={true}
              />
            </div>
            <div className="input--group">
              <label htmlFor="dnirc-dgender">Gender</label>
              <input
                type="text"
                name="dnirc-dgender"
                id="dnirc-dgender"
                value={data.gender || ""}
                readOnly={true}
              />
            </div>
            <div className="input--group">
              <label htmlFor="demail">Email</label>
              <input
                type="text"
                name="demail"
                id="demail"
                value={data.email || ""}
                readOnly={true}
              />
            </div>
            <div className="input--group">
              <label htmlFor="daddress">
                Correspondence Address [incl. State & Postcode]
              </label>
              <textarea
                readOnly={true}
                name="daddress"
                id="daddress"
                placeholder=""
                value={data.address}
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
const PlanDetails = ({
  data,
  loading,
  certificates,
  handleFilter,
  setYear,
  setMonth,
  year,
  month,
}) => {
  if (loading || !data) {
    return <div className="loading-spinner" />;
  }

  // Function to generate an array of years (last 2 years, current year, next 2 years)
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = Math.max(currentYear - 2, 2024); // Start from current year - 2 or 2024, whichever is greater
    const endYear = Math.min(currentYear + 2, currentYear); // End at current year
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    return years;
  };
  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="dashboard--info--box singal--plan--details card--default">
      <div className="title">
        <h3>SIGNAL Plans Details</h3>
      </div>
      <div className="plan--details--box row">
        {/* Details section */}
        <div className="col-md-4">
          <div className="details-container">
          <div className="plan">
              <p className="customP">Plan</p>
              <p className="customP1">{(data.plan)|| ""}</p>
            </div>
            <div className="plan">
              <p className="customP">Monthly Subscription</p>
              <p className="customP1">RM{Number(data.plan_price).toFixed(2) || ""}</p>
            </div>
            <div className="plan">
              <p className="customP">Deposit</p>
              <p className="customP1">
                RM
                {data.status === "active"
                  ? Number(data.plan_price).toFixed(2) || 0.0
                  : 0.0}{" "}
                ({data.plan})
              </p>
            </div>
            <div className="plan">
              <p className="customP">Subscription Period</p>
              <p className="customP1">
                {!data.first_line_activation_date ? 'N/A' : formatPlanDates(
                  data.current_billing_start_Date,
                  data.current_billing_end_Date
                )}
              </p>
              <p></p>
            </div>
            <div className="plan">
              <p className="customP">Telco Provider</p>
              <p className="customP1">celcomdigi</p>
            </div>
            <div className="plan">
              <p className="customP">Plan Details</p>
              <div class="row def-table-row">
                <div class="col-4 def-table-label">Data</div>
                <div class="col-8">{getDatavalue(data.plan_type)}</div>
              </div>
              <div class="row def-table-row">
                <div class="col-4 def-table-label">Calls</div>
                <div class="col-8">{getCallsvalue(data.plan_type)}</div>
              </div>
              <div class="row def-table-row">
                <div class="col-4 def-table-label">SMS</div>
                <div class="col-8">{getSMSvalue(data.plan_type)}</div>
              </div>
            </div>
            {getPolicyDocName(data.plan, "Policy Document")}
            {getPDSDocName(data.plan, "Product Disclosure Sheet")}
            {getTCDocName(data.plan, "Terms & Conditions")}
            {getClaimFormDocname(data.plan, "Insurance Claim Forms")}
            {data.plan !== "Signal Premier" ? getReimbursementClaimFormDocname(
              data.plan,
              "Reimbursement Claim Forms"
            )  : '' }
          </div>
        </div>
        {/* Table section */}
        <div className="col-md-8">
          <div className="filter-container d-flex align-items-center mb-3">
            <select
              className="form-control form-control-sm m-2"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {generateYears().map((currentYear) => (
                <option key={currentYear} value={currentYear}>
                  {currentYear}
                </option>
              ))}
            </select>

            <select
              className="form-control form-control-sm m-2"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {months.map((monthValue, index) => (
                <option key={index} value={index + 1}>
                  {monthValue}
                </option>
              ))}
            </select>

            <button className="custom-btn small m-2" onClick={handleFilter}>
              Filter
            </button>
          </div>
          <div className="table-container">
            <span><strong>Insurer</strong> : {data.insurer || 'N/A'}</span>
            <table className="table table-bordered">
              {PlansDetailsTableHeaders(data)}
              <tbody>
                {PlansDetailsCertificatesTableHeaders(data, "Policy")}
                {PlansDetailsTableRows(data, [], "Policy")}
                {PlansDetailsCertificatesTableHeaders(data, "Certificate")}
                {certificates ? (
                  <>
                    {PlansDetailsTableRows(data, certificates, "Certificate")}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      No rows found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
const BillingInfo = ({ onClick, isClicked, data, loading }) => {
  if (loading || !data) {
    return <div className="loading-spinner" />;
  }

  const handleDownload = (event, index, byIndex) => {
    event.preventDefault(); // Prevent default behavior
    try {
      const paymentData = BuildPaymentData(data, index, byIndex);

      const htmlContent = invoiceTemplate(paymentData);

      // Options for html2pdf
      const options = {
        filename: `Payment_details_${paymentData.invoice_no}.pdf`,
        html2canvas: { scale: 2 }, // Set HTML2Canvas options
        jsPDF: {
          unit: "in", // Set unit to inches
          format: [15, 20], // Set page format to letter size (8.5x11 inches)
          orientation: "portrait", // Set page orientation to portrait
        },
      };

      // Generate PDF from HTML content
      html3pdf().from(htmlContent).set(options).save();
    } catch (error) {
      alert("Unable to Download PDF At the moment, please try Later.");
      return false;
    }
  };
  const handlePayment = async (event) => {
    event.preventDefault();
    try {
      onClick(true);
      const response = await axiosWithAuth().post(
        "/api/v1/pay-current-invoice"
      );
      if (response.data.payment_url && response.data.status === 200) {
        window.location.href = response.data.payment_url;
      } else {
        alert("An unexpected error occurred. Please Try Later");
      }
      onClick(false);
    } catch (error) {
      alert("An unexpected error occurred. Please Try Later");
      onClick(false);
    }
  };
  return (
    <div className="dashboard--info--box billing--details card--default">
      <div className="title">
        <h3>Billing & Payment Details</h3>
      </div>
      <div className="plan--details--box">
        {data.CustomerCurrentInvoice && (
          <>
            <div className="plan">
              <p>Total Outstanding</p>
              <p>
                RM
                {Number(data.CustomerCurrentInvoice.plan_total_amount).toFixed(
                  2
                ) || ""}
              </p>
            </div>
            <div className="plan">
              <p>Due Date</p>
              <p>
                {DateReadingFormat(
                  data.CustomerCurrentInvoice.payment_due_date
                )}
              </p>
            </div>
            <div className="plan">
              <p>Current Invoice</p>
              <button
                type="button"
                className="custom-btn small"
                onClick={(event) => handleDownload(event, 0, 0)}
              >
                Download Here
              </button>
            </div>
            {isClicked && (
              <div className="plan">
                <div className="alert alert-success" role="alert">
                  <span>
                    <strong>Info! </strong>
                    You will now be redirected to our payment gateway eGHL site.
                  </span>
                </div>
              </div>
            )}
            <div className="plan">
              <p>Payment</p>
              <button
                type="button"
                className="custom-btn small"
                disabled={isClicked}
                onClick={(event) => handlePayment(event)}
              >
                Go To Payment
              </button>
            </div>
          </>
        )}

        <div className="table-responsive plan">
          <p>
            <b>Payment and Billing History</b>
          </p>
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>
                  <span className="head-text">Billing Cycle Date</span>
                </th>
                <th>
                  <span className="head-text">Status</span>
                </th>
                <th>
                  <span className="head-text">Due Date</span>
                </th>
                <th>
                  <span className="head-text">Billing Amount</span>
                </th>
                <th>
                  <span className="head-text">Amount Paid</span>
                </th>
                <th>
                  <span className="head-text">PDF Statement</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.CustomerInvoices.map((payment, index) => (
                <tr key={index}>
                  <td>
                    {!data.first_line_activation_date ? 'N/A' : formatMonthRange(payment.current_billing_start_Date)}
                  </td>
                  <td>{renderStatusBadge(payment.invoice_status)}</td>
                  <td>{payment.payment_due_date}</td>
                  <td>
                    {totalAmount(payment.current_invoice_amount_after_sst)}
                  </td>
                  <td>
                    {amountPaid(
                      payment.invoice_status === "paid"
                        ? payment.current_invoice_amount_after_sst
                        : 0
                    )}
                  </td>
                  <td>
                    {" "}
                    <button
                      type="button"
                      className="custom-btn small"
                      onClick={(event) => handleDownload(event, index, 1)}
                    >
                      Download PDF
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ContactInfo = () => {
  return (
    <div className="dashboard--info--box contact--details card--default">
      <div className="title">
        <h3>Contact Details</h3>
      </div>
      <div className="contact--details--box">
        <div className="contact--box">
          <h4>SIGNAL Customer Service</h4>
          <p>
            Monday to Friday, 9:00am to 6:00pm <br /> +603 2779 2419 |
            enquiry@mysignal.com.my
          </p>
        </div>
        <div className="contact--box">
          <h4>Allianz General Insurance Company (Malaysia) Berhad</h4>
          <p>
            Monday to Friday, 9:00am to 6:00pm <br /> 1300 22 5542 | +603 2264
            0700 | customer.service@allianz.com.my
          </p>
        </div>
        <div className="contact--box">
          <h4>Berjaya Sompo Insurance Berhad</h4>
          <p>
            Monday to Friday, 8:30am to 5:00pm
            <br />1 800 889 933 | +603 2170 7300 | customer@bsompo.com.my
          </p>
        </div>
        <div className="contact--box">
          <h4>CelcomDigi</h4>
          <div>
            <p style={{ color: "black" }}>Monday to Friday, 9:00am to 5:00pm</p>
            <p style={{ color: "black" }}>+6016 299 8888 | bsc@digi.com.my</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardSection = () => {
  return (
    /* ==== Dashboard :: Start ==== */
    <>
      <Main />
      <Dashboard />
    </>

    /*==== Contact Area :: End ==== */
  );
};

export default DashboardSection;
