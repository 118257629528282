import React, { useState, useEffect } from "react";
import EligibilityBox from "./eligibility";

import { useNavigate, useLocation } from "react-router-dom";

import TableAccordion from "./TableAccordion";
import DocumentsDownload from "./documentsDownload";

import DetailsForm from "./detailsForm";
import Plans from "./plans";
import {
  ValidatePlanFields,
  PlanRequiredFields,
  PlanSkipables,
  PlanTables,
  PlansFormData,
  CheckUniqueValues,
  hasValidationErrors,
  NRICAndDOBValidation,
  ValidateFormKeys,
  ValidationErrorMessage,
} from "../../utils/utils";
const SignalFamily = ({ slug,title, planType, details }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tables = PlanTables("signalfamily");
  const dataObject = PlansFormData("signalfamily");
  const [formData, setFormData] = useState(dataObject);
  const [loading, setloading] = useState(false);

  const [validationErrors, setValidationErrors] = useState(null);

  const [errors, setErrors] = useState({
    parent1: [],
    parent2: [],
    child1: [],
    child2: [],
  });

  const [customErrors, setCustomErrors] = useState({
    parent1: {},
    parent2: {},
    child1: {},
    child2: {},
  });

  const [myStyle, setToggleStyle] = useState("block");
  useEffect(() => {
    if (location.state && location.state.formData) {
      // Set the formData state using the data from location.state
      setFormData(location.state.formData);
    }
  }, [location.state]);

  const handlePurchaseChange = (value, category) => {
    const updatedData = { ...formData[category], purchase: value };
    if (value !== "new_sim_card") {
      updatedData["phone_file_required"] = true;
    } else {
      updatedData["phone_file_required"] = false;
      updatedData["phone_file"] = "";
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: updatedData,
    }));
  };

  const handleServiceChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: value,
    }));
  };
  const handlePlanChange = (value, price) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan: value,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: price,
    }));
  };

  const handleNationalityChange = (value, category) => {
    const updatedData = { ...formData[category], nationality: value };

    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: updatedData,
    }));
  };





  const handleCheckboxChange = (values) => {
    setFormData((prevState) => ({
      ...prevState,
      insurers: values,
    }));
  };

  const handleAgeChange = (isAdultdefaultValue) => {
    if (!isAdultdefaultValue) {
      setToggleStyle("none");
    } else {
      setToggleStyle("block");
    }
  };

  // Function to handle changes to input fields
  const handleChange = (category, name, value) => {
    // Make a copy of the nested array based on the category

    const { isValid, message } = ValidatePlanFields(
      name,
      value,
      formData.nationality
    );
    if (!isValid) {
      const errors = name;
      setErrors((prevErrors) => ({
        ...prevErrors,
        [category]: [...prevErrors[category], errors],
      }));
      setCustomErrors((prevErrors) => ({
        ...prevErrors,
        [category]: { ...prevErrors[category], [name]: message },
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [category]: { ...prevFormData[category], [name]: "" },
      }));
      return;
    } else {
      // Reset the error message for the specified name within the specified category
      setErrors((prevErrors) => ({
        ...prevErrors,
        [category]: prevErrors[category].filter((error) => error !== name),
      }));

      setCustomErrors((prevErrors) => ({
        ...prevErrors,
        [category]: {
          ...prevErrors[category],
          [name]: "",
        },
      }));
    }

    const updatedData = { ...formData[category], [name]: value };
    if (name === "riskLocationSameaslocation") {
      updatedData["risklocation"] =
        updatedData["address"] && value === "1" ? updatedData["address"] : "";
    } else if (name === "address") {
      updatedData["risklocation"] =
        updatedData["riskLocationSameaslocation"] === "1"
          ? updatedData["address"]
          : "";
    }

    // Define an array of fields for which parent2's fields need to be set if empty
    const fieldsToCheck = [
      "address",
      "state",
      "postcode",
      "risklocation",
      "riskLocationSameaslocation",
    ];
    // Update the specific index in the copied array

    // Update the state with the modified array
    setFormData((prevFormData) => ({
      ...prevFormData,
      [category]: updatedData,
    }));
    const totalIndexesToCheck = formData.totalindexes.slice(1); // Exclude the first index
    // Additional logic to set parent2's fields if they are empty
    if (category === "parent1" && fieldsToCheck.includes(name)) {
      totalIndexesToCheck.forEach((index) => {
        if (name === "riskLocationSameaslocation") {
          const address = formData["parent1"]["address"];

          const updatedData = {
            ...formData[index],
            risklocation: value === "1" ? address : "",
            riskLocationSameaslocation: value,
          };
          setFormData((prevFormData) => ({
            ...prevFormData,
            [index]: updatedData,
          }));
        } else if (name === "address") {
          const address = formData["parent1"]["riskLocationSameaslocation"];
          const updatedData = {
            ...formData[index],
            risklocation: address === "1" ? value : "",
            address: value,
          };
          setFormData((prevFormData) => ({
            ...prevFormData,
            [index]: updatedData,
          }));
        } else {
          const updatedData = { ...formData[index], [name]: value };
          setFormData((prevFormData) => ({
            ...prevFormData,
            [index]: updatedData,
          }));
        }
      });
    }
  };
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setloading(true);
    // Reset errors
    setErrors({
      parent1: [],
      parent2: [],
      child1: [],
      child2: [],
    });

    // Check for missing fields
    //const requiredFields = PlanRequiredFields();
   
    // if (formData.nationality !== "malaysian") {
    //   requiredFields.push("dob");
    //   requiredFields.push("country");
    //   requiredFields.push("gender");
    // }

    // Function to check for missing fields in each index of formData
    const missingFields = {};
    Object.entries(formData).forEach(([index, fields]) => {
      const requiredFields = PlanRequiredFields();
      const skipables = PlanSkipables();
      if (formData[index]['nationality'] !== "malaysian") {
        requiredFields.push("dob");
        requiredFields.push("country");
        requiredFields.push("gender");
      }
     
      if (skipables.includes(index)) {
        return;
      }
      if (index === "child2" && formData["child2"]["required"] === "false") {
        return;
      }
      
      //console.log("requiredFields",requiredFields)
      const missingInIndex = requiredFields.filter((field) => !fields[field]);
      if (missingInIndex.includes("phonefile")) {
        if (!formData[index]["phone_file_required"]) {
          const indexToRemove = missingInIndex.indexOf("phonefile");
          if (indexToRemove !== -1) {
            missingInIndex.splice(indexToRemove, 1);
          }
        }
      }
      if (missingInIndex.length > 0) {
        missingFields[index] = missingInIndex;
      }
    });
    //console.log("missingFields", missingFields);
    const { updatedErrors } = CheckUniqueValues(formData);
    setCustomErrors((prevCustomErrors) => ({
      ...prevCustomErrors,
      ...updatedErrors,
    }));

    // setErrors((prevErrors) => {
    //   const updatedErrors = { ...prevErrors };
    //   for (const [key, error] of Object.entries(CustomeErrors)) {
    //     updatedErrors[key] = [...prevErrors[key] || [], ...error];
    //   }
    //   return updatedErrors;
    // });
    if (
      !Object.values(updatedErrors).every(
        (obj) => Object.keys(obj).length === 0
      )
    ) {
      setloading(false);
      return;
    }

    if (Object.keys(missingFields).length > 0) {
      Object.keys(missingFields).forEach((key) => {
        const errors = missingFields[key];
        //console.log("errors",errors)
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: [...prevErrors[key], ...errors],
        }));
      });
      setloading(false);
      return; // Don't proceed with submission if there are missing fields
    }

    const { DOBupdatedErrors } = NRICAndDOBValidation(formData, "1-75");
    //console.log("DOBupdatedErrors",DOBupdatedErrors,"updatedErrors",updatedErrors)
    setCustomErrors((prevCustomErrors) => ({
      ...prevCustomErrors,
      ...DOBupdatedErrors,
    }));

    if (
      !Object.values(DOBupdatedErrors).every(
        (obj) => Object.keys(obj).length === 0
      )
    ) {
      setloading(false);
      return;
    }

    try {
      await ValidateFormKeys(formData);
      navigate("/confirmation", { state: { formData: formData } });
    } catch (error) {
      setloading(false);
      setValidationErrors(
        error.response.data.errors || [error.response.data.message]
      );
    }
  };
  return (
    /*==== Connect Area :: Start ==== */
    <section className="singlelife--registration singlefamily--registration">
      <div className="container">
        {/* start section title  */}
        <div className="section--title">
          <h3>{title}</h3>
          <p>{details}</p>
        </div>
        {/* end section title  */}
        <div className="row justify-content-center">
          <div className="col-md-11 mt_30">
            <div className="benefit--box">
              <h3>
                <img src="../assets/images/gridicons_dropdown.svg" alt="" />
                Benefits
              </h3>
              <form className="benfit--form" onSubmit={handleSubmit}>
                {/* benefit tab  */}
                <TableAccordion tables={tables} />
                {/* documents upload  */}
                <DocumentsDownload
                    slug = {slug}
                     pds_doc={"Signal Family PDS.pdf"}
                     policy_doc={"Signal Family - Policy.pdf"}
                  // pds_doc={"SIGNAL Family - PDS 1.pdf,SIGNAL Family - PDS 2.pdf"}
                  // policy_doc={"SIGNAL Family - Policy 1.pdf,SIGNAL Family - Policy 2.pdf"}
                />
                <EligibilityBox
                  labelText="Are you 75 years old or below currently?"
                  warningText="(We are sorry. You need to be  75 years old or below to register for this plan)"
                  onSelectionChange={handleNationalityChange}
                  onAgeChange={handleAgeChange}
                  preFormData={formData}
                  categories={["parent1", "parent2", "child1", "child2"]}
                />
               

                <div
                  className="eligibility--hidden--items"
                  style={{ display: myStyle }}
                >
                  {/* plan box wrapper  */}
                  <div className="plan--box--wrapper radio--box--default mt_65">
                    <h3>Plans</h3>
                    <p className="mt_10 mb_20">
                      Note: For family who like to add on more children to this
                      plan, kindly contact enquiry@mysignal.com.my or +603 2779
                      2419
                    </p>
                    <Plans
                      preFormData={formData}
                      categories={["parent1", "parent2", "child1", "child2"]}
                      setPurchaseOption={handlePurchaseChange}
                      setServiceOption={handleServiceChange}
                      setPlanOption={handlePlanChange}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="personal--details--wrapper mt_65">
                    <h3>Personal Details</h3>
                    {/* personal details  */}

                    <DetailsForm
                      category="parent1"
                      onFormUpdate={handleChange}
                      errors={errors}
                      preformData={formData}
                      classValue={
                        "personal--details--box card--default personal--details--box1"
                      }
                      title={"Parent 1"}
                      label={"parent1"}
                      customErrors={customErrors}
                    />
                    <DetailsForm
                      category="parent2"
                      onFormUpdate={handleChange}
                      errors={errors}
                      preformData={formData}
                      classValue={
                        "personal--details--box card--default personal--details--box2 mt_25"
                      }
                      title={"Parent 2"}
                      label={"parent2"}
                      customErrors={customErrors}
                    />
                    <DetailsForm
                      category="child1"
                      onFormUpdate={handleChange}
                      errors={errors}
                      preformData={formData}
                      classValue={
                        "personal--details--box card--default personal--details--box3 mt_25"
                      }
                      title={"Child 1"}
                      label={"child1"}
                      customErrors={customErrors}
                    />
                    <DetailsForm
                      category="child2"
                      onFormUpdate={handleChange}
                      errors={errors}
                      preformData={formData}
                      classValue={
                        "personal--details--box card--default personal--details--box4 mt_25"
                      }
                      title={"Child 2"}
                      label={"child2"}
                      customErrors={customErrors}
                    />

                    {(errors || customErrors) &&
                      hasValidationErrors(errors, customErrors) && (
                        <div
                          className="alert alert-danger d-inline-block mt-5"
                          role="alert"
                        >
                          Form Validation Failed, Please Clear Form Errors
                        </div>
                      )}

                    {validationErrors && (
                      <ValidationErrorMessage errors={validationErrors} />
                    )}
                    <div className="benefit--submit--btn mt_35">
                      <button type="submit" className="button">
                        {loading ? `Validating` : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignalFamily;
