import React, { useState } from 'react';

const EligibilityBox = ({ categories,labelText,warningText, onSelectionChange,onAgeChange,preFormData }) => {
    const [isAdultdefaultValue, setIsAdultdefaultValue] = useState(true);
    const [myStyle, setToggleStyle] = useState("none");

    const handleAdultSelectionChange = (value,category) => {
       
        if(value !=="adult-yes"){
            setToggleStyle("block")
        } else {
            setToggleStyle("none")
        }
        setIsAdultdefaultValue(value === "adult-yes");
        onAgeChange(value === "adult-yes");
    };

    const handleNationalityChange = (value,category) => {
        console.log(value,category)
        onSelectionChange(value,category)
        //onSelectionChange(event.target.value);
    };

    const Title = (category) => {
        let key = category.toUpperCase();
        console.log("category",category)
        let title = "";
        switch (key) {
          case "PARENT1":
            title = "(Parent 1)";
            break;
          case "PARENT2":
            title = "(Parent 2)";
            break;
          case "CHILD1":
            title = "(Child 1)";
            break;
          case "CHILD2":
            title = "(Child 2)";
            break;
    
          default:
            return "";
        }
        return title;
      };



    return (
        <>
        {categories.map((category, index) => (
       
        <div className="eligibility--box--wrapper mt_65" key={index}>
            <h3>Eligibility {Title(category)}</h3>
            <div className="eligibility--box radio--box--default card--default">
                <div className="adult--select--box">
                    <h4>{labelText}</h4>
                    <div className="input--group">
                        <input type="radio"  id={`adult-yes-${category}`}  name={`adult-check-group-${category}`} value="adult-yes" checked={isAdultdefaultValue}  
                         onChange={() =>
                            handleAdultSelectionChange("adult-yes", category)
                          }
                         />
                        <label htmlFor={`adult-yes-${category}`}>Yes</label>
                    </div>
                    <div className="input--group">
                        <input type="radio" id={`adult-no-${category}`} name={`adult-check-group-${category}`} value="adult-no" checked={!isAdultdefaultValue} 
                             onChange={() =>
                                handleAdultSelectionChange("adult-no", category)
                              }
                        />
                        <label htmlFor={`adult-no-${category}`}>No</label>
                    </div>
                    <p style={{ display: myStyle }} className="eligibility--hidden--text">{warningText}</p>
                </div>
                <div className="nationality--select--box">
                    <h4>Select your nationality status</h4>
                    <div className="input--group">
                        <input type="radio"  id={`malaysian-${category}`}  name={`nationality--group-${category}`} value="malaysian" checked={preFormData[category]["nationality"] === "malaysian"} 
                         onChange={() =>
                            handleNationalityChange("malaysian", category)
                          }
                         />
                        <label htmlFor={`malaysian-${category}`}>Malaysian</label>
                    </div>
                    <div className="input--group">
                        <input type="radio" id={`non-malaysian-${category}`} name={`nationality--group-${category}`} value="non-malaysian" checked={preFormData[category]["nationality"] === "non-malaysian"}
                            onChange={() =>
                                handleNationalityChange("non-malaysian", category)
                              }
                        />
                        <label htmlFor={`non-malaysian-${category}`}>Non-Malaysian (legally residing, studying or working in Malaysia)</label>
                    </div>
                </div>
            </div>
        </div>
        
         ))}
    </>
    );
}

export default EligibilityBox;
