import React, { useState } from "react";
import { AssetsPDFiles } from "../utils/utils";
const Footer = ({ hasPartner = false }) => {
  const [year] = useState(new Date().getFullYear());
  const facebook = "https://www.facebook.com/mysignalsdn/";
  const instagram = "https://www.instagram.com/mysignalsdn/";
  const twitter = "https://www.twitter.com/mysignalsdn";
  const tiktok = "https://www.tiktok.com/@mysignalsdn";
  const linkedin = "https://www.linkedin.com/company/mysignalsdn/";
  const youtube = "https://www.youtube.com/@MySIGNALTelcoInsurance";

  return (
    /*==== Footer :: Start ==== */
    <footer>
       <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 50 }}>
    <a
      href="https://wa.me/60108231620"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
      <div
        className="bg-success p-3 rounded-circle shadow-lg"
        style={{
          width: '60px',  // Adjust the size here
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          className="text-white"
          fill="currentColor"
          style={{ width: '40px', height: '40px' }}  // Adjust the SVG size here
        >
          <path d="M16 0C7.163 0 0 7.163 0 16c0 2.837.736 5.584 2.132 8.001L.001 32l8.12-2.125A15.921 15.921 0 0 0 16 32c8.837 0 16-7.163 16-16S24.837 0 16 0zm7.579 22.528c-.327.917-1.589 1.518-2.75 1.713-.738.121-1.692.217-4.921-.987-4.124-1.62-6.774-5.847-6.981-6.127-.207-.28-1.665-2.217-1.665-4.23 0-2.015 1.049-3.008 1.42-3.427.369-.414.804-.52 1.071-.52.258 0 .517.002.744.014.241.011.563-.091.88.671.327.776 1.116 2.67 1.214 2.867.097.197.172.43.033.694-.138.262-.207.424-.41.644-.207.222-.426.49-.61.657-.207.19-.426.396-.184.78.241.388 1.076 1.764 2.305 2.855 1.589 1.414 2.93 1.855 3.304 2.045.373.19.595.172.817-.104.207-.242.938-1.092 1.19-1.467.241-.38.488-.322.818-.19.329.127 2.11 1 2.473 1.183.363.184.604.276.69.428.086.152.086.879-.241 1.797z" />
        </svg>
      </div>
    </a>
  </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-9">
            <div className="copyright">
              <p>© {year} MySignal Marketing Sdn Bhd. All rights reserved.</p>
            </div>
          </div>
         
          <div className="col-lg-6 col-sm-3">
            <div className="footer--links">
              <ul className="footer--menu">
                <li>
                  <a href={AssetsPDFiles("SIGNAL_privacy_notice.pdf", hasPartner)}>
                    Privacy Notice
                  </a>
                </li>
                <li>
                  <a href={AssetsPDFiles("SIGNAL_condition_of_use.pdf", hasPartner)}>
                    Conditions of Use
                  </a>
                </li>
              </ul>
              <div className="social--icon">
                <a target="blank" href={facebook}>
                  <i className="bi bi-facebook"></i>
                </a>
                <a target="blank" href={instagram}>
                  <i className="bi bi-instagram"></i>
                </a>
                <a target="blank" href={twitter}>
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a target="blank" href={youtube}>
                  <i className="bi bi-youtube"></i>
                </a>
                <a target="blank" href={tiktok}>
                  <i className="bi bi-tiktok"></i>
                </a>
                <a target="blank" href={linkedin}>
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    /* ==== Footer :: End ==== */
  );
};

export default Footer;
