import React from 'react';
import { AssetsURLWhenInfluencer } from '../../utils/utils';

const Common = ({hasPartner = false}) => {
	
	const getImageURL = () => {
		let url = AssetsURLWhenInfluencer("chevron-left.svg",hasPartner)
		return url
	  };
    return (
        
	
        // <section className="inner--hero registration-hero single--life--registration" style={{ backgroundImage: 'url(../assets/images/background_hero.png)' }}>
		<section className="inner--hero registration-hero single--life--registration theme-background">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div className="inner--text">
								<h4 className="tag-box">Revolutionizing Your Mobile Experience</h4>
								<h3 className="heading">Join the Future of Connectivity with MYSIGNAL</h3>
								<p>Seamless Registration for Telco-Infused Insurance and Takaful Plan - Empowering Your Mobile Experience.</p>
								<div className="go--back">
									<a href="/" className="button">
									<img src={getImageURL()} alt="" />
									<span>Go back</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>    
    );
}

export default Common;
